import React from 'react';
import './modalstyle.css';

class Modal extends React.Component {
  render() {
    return (
      this.props.visible ?
        <div className="modal-root">
          <div className="modal-bg" onClick={this.props.close}></div>
          <div className="modal-fg">{this.props.children}</div>
        </div>
        : null
    );
  }
}

export default Modal
