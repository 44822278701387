import React, { useEffect, useState } from 'react';
import './App.css';
import io from 'socket.io-client';
import Game from './Game';

import { generateName } from './util/randomName';


import NoSleep from 'nosleep.js';

function App() {
	const [isInGame, setisInGame] = useState(false);
	const [serverSocket, setServerSocket] = useState(false);

	let nameRef = React.createRef();
	var noSleep = new NoSleep();

	document.addEventListener('click', function enableNoSleep() {
		// document.removeEventListener('click', enableNoSleep, false);
		if (!noSleep.enabled) noSleep.enable();
	}, false);

	useEffect(() => {
		document.addEventListener('click', function enableNoSleep() {
			// document.removeEventListener('click', enableNoSleep, false);
			// wakelock-lazy-polyfill
			// StayAwake.enable();/
		}, false);


		// window.addEventListener("focus", handleBrowserState);
		// window.addEventListener("blur", handleBrowserState);
	});

	var backendurl = "https://cardsback.redannick.com"
	if (document.location.host === "localhost:3000") backendurl = "http://localhost:3001"


	let joinGame = (name) => {
		let socket = io(backendurl, {
			reconnection: true
		});
		setServerSocket(socket);
		socket.on('connect', function () {
			socket.emit("teamname", name);
			socket.emit('join', window.location.pathname.substr(1));
			socket.teamname = name;
		});
		socket.on('disconnect', function () {
			// setisInGame(false);
		});
		socket.on('joined', (roomname) => {
			document.title = roomname;
			setisInGame(true);
			socket.roomname = roomname;
			window.history.pushState({ roomname: roomname }, "roomname", roomname);
		})

	}
	let handleNameSubmit = (e) => {
		e.preventDefault();
		var name = nameRef.current.value;
		joinGame(name || generateName());

	}
	return (
		<div className="App">
			<header className="App-header">
				Cards Against Humanity
				{!isInGame ? <form onSubmit={handleNameSubmit}>
					<input type="text" ref={nameRef} placeholder="Team Name"></input>
					<input type="submit" value="Join Game"></input>
				</form>
					: null
				}
			</header>
			{isInGame ? <Game socket={serverSocket} /> : null}
		</div >
	);
}

export default App;
