import React, { useEffect, useState, Fragment } from 'react';
import Modal from './modal/Modal';


export default function Game(props) {
	const { socket } = props;

	const [users, setUsers] = useState([]);
	const [started, setStarted] = useState(false);
	const [iamczar, setIamczar] = useState(false);
	const [hand, setHand] = useState([]);
	const [score, setScore] = useState(0);
	const [submittedCards, setSubmittedCards] = useState(null);
	const [blackCard, setBlackCard] = useState(null);

	const [showCzarMessage, setShowCzarMessage] = useState(false);
	const [showWinningMessage, setShowWinningMessage] = useState(false);
	const [showGameLinkMessage, setShowGameLinkMessage] = useState(false);



	//unmount
	useEffect(() => {
		if (socket.isSetup) {
			return;
		}
		socket.isSetup = true;
		socket.on("users", (users) => {
			setUsers(users);
			var dummycardsObj = {};
			users.forEach(user => {
				if (user.teamname !== socket.teamname) dummycardsObj[user.teamname] = null;
			});

			setSubmittedCards(dummycardsObj)

		});
		socket.on("recieveWhiteCards", cards => {
			setHand(cards);
		});
		socket.on("recieveBlackCard", newBlackCard => {
			newBlackCard = newBlackCard.split("_").join("______");
			setBlackCard(newBlackCard);
			window.scrollTo(0, 0);

		})
		socket.on("gameStarted", () => {
			setStarted(true);
		});
		socket.on("notczar", () => {
			deselectAllCards();
			setIamczar(false);
		})
		socket.on("czar", () => {
			setIamczar(true);
			setShowCzarMessage(true);

		})
		socket.on("winHand", () => {
			setScore(prevScore => prevScore + 1);
			// alert("nice, you won the hand!");
			setShowWinningMessage(true);
		})
		socket.on("reportCardSelected", (user, card) => {
			setSubmittedCards(prevState => ({
				...prevState,
				[user]: card
			}))
		})
		return () => {
			// socket.leave();
		}
	})


	const startGame = () => {
		socket.emit("startgame");
	}


	const deselectAllCards = () => {



		var cards = document.querySelectorAll('.card');
		for (let i = 0; i < cards.length; ++i) {
			cards[i].classList.remove("card-selected");
		}
		const dummycardsObj = {};
		users.forEach(user => {
			if (user.teamname !== socket.teamname) dummycardsObj[user.teamname] = null;
		});


		setSubmittedCards(prevState => ({
			...prevState,
			...dummycardsObj
		}))


	}

	const selectCard = (e) => {
		if (iamczar) return;
		deselectAllCards();
		var cardElement = e.target;
		cardElement.classList.add("card-selected")
		socket.emit("chooseCard", cardElement.dataset.key);

	}

	const selectWinningCard = (e) => {
		socket.emit("selectWinningCard", e.target.dataset.key)
	}

	const shareLink = (e) => {
		let link = window.location.protocol + "//" + document.location.host + "/" + socket.roomname;
		if (navigator.share) {
			navigator.share({
				title: 'Cards Against Humanity',
				text: 'Play Cards Against Humanity with me!',
				url: link,
			})
				.catch((error) => console.log('Error sharing', error));
		} else {
			navigator.permissions.query({ name: "clipboard-write" }).then(result => {
				if (result.state === "granted" || result.state === "prompt") {
					/* write to the clipboard now */
					navigator.clipboard.writeText(link).then(function () {
						/* clipboard successfully set */
					}, function () {
						/* clipboard write failed */
					});
				}
			});
			setShowGameLinkMessage(true);
		}
	}

	let allAnswers = true;
	for (const key in submittedCards) {
		if (submittedCards.hasOwnProperty(key)) {
			const element = submittedCards[key];
			if (element === null) allAnswers = false;
		}
	}

	return <div className="game">
		<div>You are "{socket.teamname}". Click the link to copy or share with 2 or more friends to play:<br /> <span id="gamelink" onClick={shareLink}>{window.location.protocol + "//" + document.location.host + "/" + socket.roomname}</span></div>
		<br />
		<div>
			{/* <ul> */}
			<strong>Players: </strong>
			{users.map((user, i) => {
				return <span key={"userlist_" + i} >{(i > 0 ? ", " : "") + user.teamname}</span>
			})}
			{/* </ul> */}
			< br />
			<div className="score">Score: {score}</div>
			{!started ?
				<Fragment>
					<h4>Everyone here? Let's get started. <br /></h4>
					<button onClick={startGame}>I went for a poo most recently! I'll start.</button>
				</Fragment>
				: null
			}
			{blackCard ?
				<div className="hand">
					<div className="card blackcard main-blackcard"  > {blackCard}</div>
				</div>
				: null}
			{iamczar ?
				<Fragment>
					<div className="you-are-czar">You are the card Czar - Choose the best response!</div>
					<div className="answerhand">
						<h3>Submitted answers...</h3>
						<div className="hand">

							{submittedCards ?
								Object.entries(submittedCards).map(([user, card]) => {
									return <div key={user}>
										{/* <h4 className="centeredtext">...</h4> */}
										{card !== null ?
											<div className={`card whitecard ${allAnswers ? '' : 'question'}`} key={user} data-key={user} onClick={(e) => { selectWinningCard(e) }}> {allAnswers ? card : "?"}</div>
											: <div className="card yellowcard" />}
									</div>
								})
								: null
							}
						</div>
					</div>
				</Fragment>
				: null}
			{hand.length > 0 ?
				<div className={`hand ${iamczar ? 'disabled' : ''}`}>
					{hand.map((card, i) => {
						return <div className="card whitecard" key={card} data-key={card} data-index={i} onClick={(e) => { selectCard(e) }}> {card}</div>
					})}
				</div>
				: null}


		</div>
		<Modal visible={showCzarMessage} close={() => { setShowCzarMessage(false); setShowWinningMessage(false); }}>
			{showWinningMessage ? <Fragment>You won the hand!<br /><br /></Fragment> : null}
			You are the Card Czar - read aloud:<br /><br />
			<div className="hand">
				<div className="card blackcard"  > {blackCard}</div>
			</div>
			<br />
			<button onClick={() => setShowCzarMessage(false)} >OK</button>
		</Modal>

		<Modal visible={showGameLinkMessage} close={() => setShowGameLinkMessage(false)}>
			{window.location.protocol + "//" + document.location.host + "/" + socket.roomname}
			<br />
			<br />
			Game link copied - send it to your friends!
			<br />
			<br />
			<button onClick={() => setShowGameLinkMessage(false)} >OK</button>
		</Modal>

	</div >
}